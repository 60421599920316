import React, {useState, useLayoutEffect} from "react";
import {Container, Row, Col, Button} from "react-bootstrap";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {Link} from "gatsby";

import {Parallax, ParallaxLayer} from 'react-spring/renderprops-addons'
import {Spring} from 'react-spring/renderprops'

import VisibilitySensor from "react-visibility-sensor";


// ---------- Import All SVGs  ---------- //

import Opening0 from "../images/opening-0.svg";
import Opening1 from "../images/opening-1.svg";
import Opening2 from "../images/opening-2.svg";
import Opening3 from "../images/opening-3.svg";
import Opening4 from "../images/opening-4.svg";
import Opening5 from "../images/opening-5.svg";
import Opening6 from "../images/opening-6.svg";
import Opening7 from "../images/opening-7.svg";
import Opening9 from "../images/opening-9.svg";
import Opening10 from "../images/opening-10.svg";
import Sun from "../images/circle.svg";

import Starsbig from "../images/start-big.svg";
import Title from "../images/title.svg";
import Grass from "../images/grass.svg";
 

import Cat1 from "../images/cat-1.svg";
import Cat2 from "../images/cat-2.svg";
import Cat3 from "../images/cat-3.svg";
import Cat5 from "../images/cat-5.svg";
import Cat6 from "../images/cat-6.svg";
import Cat7 from "../images/cat-7.svg";
import Cat8 from "../images/cat-8.svg";

import EarAnimated from "../images/ear-animated.svg";
import EyeAnimated from "../images/eye-animated.svg";
import NoseAnimated from "../images/nose-animated.svg";
import MouthAnimated from "../images/mouth-animated.svg";
import TouchAnimated from "../images/touch-animated.svg";

// ---------- Colors Variables ---------- //

const black = '#000 !important';
const white = '#fff !important';
const darkGreen = '#274043 !important';
const green = '#448B6F';
const red = '#F0625D';
const skin = '#EDD1A0';
const yellow = '#F5AA28';


// ---------- Component ---------- //

class IndexPage extends React.Component {

    // constructor(props) {
    //     super(props)
    //     this.handleScroll = value => this.parallax && this
    //         .parallax
    //         .scrollTo(value)
    // }

// ---------- Windnow width listener ---------- //

    // state = {
    //   windowWidth: undefined
    // }

    // handleResize = () => this.setState({
    //   windowWidth: window.innerWidth
    // });
  
    // componentDidMount() {
    //   this.handleResize();
    //   window.addEventListener('resize', this.handleResize)
    // }
  
    // componentWillUnmount() {
    //   window.removeEventListener('resize', this.handleResize)
    // }
  
    
    render() {

      
      
  
        return (

            <Layout>
                <SEO title="Home"/> 


{/* ---------------------- Page Contents Here --------------------- */}

<Parallax pages={5} ref={ref => this.parallax = ref}>

{/* ---------- Sections Color  ---------  */}

<ParallaxLayer offset={0} speed={0} style={{ backgroundColor: green,display: 'flex' }} />
<ParallaxLayer offset={1} speed={0} factor={0.5} style={{ backgroundColor: green, display: 'flex' }} />

{/* **** Stars *****  */}
<ParallaxLayer
          offset={0}
          speed={0}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Starsbig  id='stars' style={{opacity: '0.2' }}/>
</ParallaxLayer>


{/* ---------- Opening Image ----------  */}

{/* **** Cloud *****  */}
<ParallaxLayer
          offset={0.5}
          speed={0.5}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Opening0  id='cloud' style={{width: '95%'}}/>
</ParallaxLayer>

{/* **** Title *****  */}
{/* <ParallaxLayer
          offset={0.15}
          speed={0.5}
          style={{ display: 'flex', alignItems: 'left', justifyContent: 'left'}}
          >
              <div style={{ display: 'block', width: '100%'}}>
                <Container>
                <Row>
                  <Col className='index-sections-titles'>
                <h1 style={{ marginBottom: '-1rem'}}>Sensory</h1>
                <h2 style={{ color: skin}}>Storytelling</h2>

                </Col>
                </Row>
                </Container>
              </div>
</ParallaxLayer> */}

<ParallaxLayer
          offset={0.15}
          speed={0.5}
          style={{ display: 'flex', alignItems: 'left', justifyContent: 'left'}}
          >
            <Container>

            <VisibilitySensor>
        {({ isVisible }) => (
                  <Spring delay={100} to={{ opacity: isVisible ? 1 : 0 }}>
                  {({ opacity  }) => 
              <div style={{ opacity }}>


            <Title className='svg-media-cat'/>

            </div>
                  }
                  </Spring>
                  )}
        </VisibilitySensor>


            </Container>


</ParallaxLayer>


<div id='Opening Image'>
            
      {/* **** light big *****  */}
      <ParallaxLayer
                offset={0.5}
                speed={0.3}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', filter: 'blur(8px)' }}>
                <Opening5  id='light-big' className='svg-media-opening'/>
      </ParallaxLayer>

      {/* **** Butterfly *****  */}
      <ParallaxLayer
                offset={0.6}
                speed={0.55}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Opening1   id='butterfly' className='svg-media-opening'/>
      </ParallaxLayer>


      {/* **** wind *****  */}
      <ParallaxLayer
                offset={0.68}
                speed={0.6}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Opening2  id='nose' className='svg-media-opening'/>
      </ParallaxLayer>

      {/* **** eye *****  */}
      <ParallaxLayer
                offset={0.72}
                speed={0.75}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Opening4   id='eye' className='svg-media-opening'/>
      </ParallaxLayer>

      {/* **** light small *****  */}
      <ParallaxLayer
                offset={0.5}
                speed={0.3}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Opening6  id='light-small' className='svg-media-opening'/>
      </ParallaxLayer>

      {/* **** Duck Shark *****  */}
      <ParallaxLayer
                offset={0.6}
                speed={0.47}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Opening9  id='wind' className='svg-media-opening'/>
      </ParallaxLayer>

      {/* **** book *****  */}
      <ParallaxLayer
              offset={0.6}
              speed={0.4}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Opening7  id='book' className='svg-media-opening'/>
      </ParallaxLayer>

      {/* **** bear *****  */}
      <ParallaxLayer
                offset={0.5}
                speed={0.3}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            
                <Opening3  id='bear' className='svg-media-opening'/>
                    
      </ParallaxLayer>

      {/* **** bear blink *****  */}
      <ParallaxLayer
                offset={0.5}
                speed={0.3}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            
                <Opening10  id='bear' className='svg-media-opening'/>
                    
      </ParallaxLayer>

</div>
     
{/* **** Cloud *****  */}
<ParallaxLayer
          offset={1}
          speed={0.8}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Opening0 id='cloud' style={{width: '95%'}}/>
</ParallaxLayer>

{/* **** 'Welcome'  *****  */}
{/* <ParallaxLayer
          offset={1}
          speed={1}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}
          >
                      <div className='welcome-bubble text-center'>
                       <h1>Welcome...</h1> 
                        </div >
                
</ParallaxLayer> */}


{/* ---------- Welcome Cat  ----------  */}

<ParallaxLayer offset={1.5} speed={0} factor={0.5} style={{ backgroundColor: red }} id='welcome cat'/>
<ParallaxLayer offset={2} speed={0}  factor={0.5} style={{ backgroundColor: red }} id='welcome cat'/>


<div id='Cat'>

{/* <ParallaxLayer
          offset={1.5}
          speed={1}
          style={{ display: 'flex', alignItems: 'left', justifyContent: 'left'}}
          >
              <div style={{ display: 'block', width: '100%'}}>
                <Container>
                <Row>
                  <Col className=''>
                <h1>Welcome...</h1>

                </Col>
                </Row>
                </Container>
              </div>
</ParallaxLayer> */}

    {/* **** Sun *****  */}
    <ParallaxLayer 
              offset={1.9}
              speed={2}
              factor={3.7}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Sun  style={{filter: 'blur(8px)'}}/>
    </ParallaxLayer>

   {/* **** Cat *****  */}
    <ParallaxLayer
              offset={1.7}
              speed={0.4}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Cat3  className='svg-media-cat'/>
    </ParallaxLayer>

    {/* **** Water *****  */}
    <ParallaxLayer
              offset={1.7}
              speed={0.5}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Cat6 id='water' className='svg-media-cat'/>
    </ParallaxLayer>

    {/* **** Eye Flower *****  */}
    <ParallaxLayer
              offset={1.8}
              speed={0.7}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Cat1 id='eye-flower' className='svg-media-cat'/>
    </ParallaxLayer>

    {/* **** Wind Hand *****  */}
    <ParallaxLayer
              offset={1.7}
              speed={0.5}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Cat7 id='wind-hand' className='svg-media-cat'/>
    </ParallaxLayer>

    {/* **** Cat Blink *****  */}
    <ParallaxLayer
              offset={1.7}
              speed={0.4}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Cat8 id='cat-blink' className='svg-media-cat'/>
    </ParallaxLayer>

       {/* **** Cat *****  */}
       <ParallaxLayer
              offset={1.7}
              speed={0.5}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Cat2 id='cat' className='svg-media-cat'/>
    </ParallaxLayer>

</div>



{/* ----------  Introduction Texts ----------  */}


{/* <ParallaxLayer
              offset={2.1}
              // offset={(this.state.windowWidth < 576) ? 2.9 : (this.state.windowWidth < 769) ? 2.9 : (this.state.windowWidth < 1025) ? 2.9 : 3.5}
              factor={0}
              speed={1}
              style={{ display: 'block', alignItems: 'center', justifyContent: 'center' }}
              id={'welcome-text'}
              // style={{ overflowY: 'visible'}} onScroll={e => e.stopPropagation()} 
              >
     <Container fluid className='mt-4' style={{ backgroundColor: yellow }} >



      </Container>
      

</ParallaxLayer> */}

<ParallaxLayer offset={2.5} speed={0}  factor={1} style={{ backgroundColor: skin }} id='welcome cat' />
<ParallaxLayer offset={3.5} speed={0}  factor={1} style={{ backgroundColor: green }} id='welcome cat' />
<ParallaxLayer offset={4.5} speed={0} factor={0.5} style={{ backgroundColor: green, display: 'flex' }} />






<ParallaxLayer
offset={2.2} 
speed={0.5}  
factor={1} 
style={{ }} 
id='welcome texts'
>
  <Container fluid style={{ backgroundColor: skin}}>

  
<Row className='grass'>

<Col className='pt-5' xs={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 8, offset: 2 }}>

  <Row >
            <Row className='pt-5 text-center'>
              <Col>
                <p className='red'>
                Welcome to the world of...
                </p>  
                <h4>
                Sensory Storytelling!
                </h4>  

                <p className='text-left pt-5'> 
                 Here you can learn how to tell a favourite story or create your own by using the sense of:
                 
                  {/* This wesbsite has suggestions and a template on how to
                adapt a favourite story or create your own through the use of the five senses: */}
                </p>  
              </Col>
            </Row>

            <Row >
              <Col xs={4} className='text-center'>
              <TouchAnimated className='svg-media-100'/>
              <h1 className='red mt-3'>Touch</h1>
              </Col>
              <Col xs={4} className='text-center'>
              < EyeAnimated className='svg-media-100'/>
              <h1 className='red mt-3'>Sight</h1>
              </Col>
              <Col xs={4} className='text-center'>
              < EarAnimated className='svg-media-100'/>
              <h1 className='red mt-3'>Sound</h1>
              </Col>
          </Row>

          <br/>
          <Row style={{width: '100%'}}>

            <Col xs={{ span: 4, offset: 2 }}  className='text-center'>
            <MouthAnimated className='svg-media-100'/>
            <h1 className='red mt-3'>Taste</h1>
            </Col>
            <Col xs={4}  className='text-center'>
            <NoseAnimated className='svg-media-100'/>
            <h1 className='red mt-3'>Smell</h1>
            </Col>
          </Row>

          <Row className='pt-5'>
            <Col xs={12} >
              <p >
              Sensory storytelling is an approach that provides more opportunities for children to understand, interpret and engage with a story. You can start with a favourite bedtime story, and eventually create your own stories – all with the help of everyday items found at home. Use your imagination! Sensory storytelling is easy, fun and an opportunity to spend creative time with your children or students. 
              </p>  
            </Col>
          </Row>

          <Row className='pt-5 text-center'>
            <Col xs={12} >
              <div className='text-center'>
            <Cat5 className='svg-media mb-5' style={{  width: '50%', maxWidth: '500px' }}/>
            </div>
              <h3 className='mb-5'>
              Keep in Mind...
              </h3>
              <p className='text-left'>
              A story can also be told while preparing dinner, or at bathtime – storytelling isn’t just for bedtime...
              </p>  
              <p className='text-left dark-green'>
                ...to create a safe enviorment please always make sure you are using objects responsibly.  
              </p>
            </Col>
            <Col>
            <div className='next-page'>
            <Link as={Link} activeClassName="active" to="/how">Let's learn how to make a sensory story...</Link>
            </div>
            </Col>

          </Row>


    </Row>



  </Col>

</Row>

</Container>

</ParallaxLayer>



     {/* **** Next Button 1 *****  */}
     <ParallaxLayer
                offset={0}
                speed={0.1}
                // factor={1.2}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                onClick={() => this.parallax.scrollTo(0.5)}
                >                    
      </ParallaxLayer>
      <ParallaxLayer
                offset={0.5}
                speed={0.1}
                // factor={1.2}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                onClick={() => this.parallax.scrollTo(1.5)}
                >                    
      </ParallaxLayer>
      <ParallaxLayer
                offset={1.5}
                speed={0.1}
                // factor={1.2}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                onClick={() => this.parallax.scrollTo(2)}
                >                    
      </ParallaxLayer>


{/* {/* -------------------------- */}


       {/* **** Dont forget  *****  */}


       {/* <ParallaxLayer
          offset={3}
          speed={2}
          style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}
          >

            <div style={{ display: 'block', width: '100%', backroundColor: red }} className= 'ml-4'>
            
            <Row>
              <Col>
                  <VisibilitySensor>
                  {({ isVisible }) => (
                    <Spring delay={0} to={{ opacity: isVisible ? 1 : 0 }}>
                    {({ opacity }) => 
                      <Cat5 id='cat-blink' className='svg-media' style={{ opacity, display: 'block', width: '25%', maxWidth: '150px' }}/>
                    }
                    </Spring>
                    )}
                  </VisibilitySensor>
                </Col>
              </Row>

              <Row>
                <Col xs={6} md={6} lg={5} xl={4}>
                  <VisibilitySensor>
                    {({ isVisible }) => (
                      <Spring delay={100} to={{ opacity: isVisible ? 1 : 0 }}>
                        {({ opacity }) => 
                      
                      <div style={{ opacity }} className='dont-forget dark-green'> 
                        <h2>
                          Don’t Forget...
                        </h2>
                        <p>
                          A story can also
                          be told while preparing dinner, or at bathtime – storytelling isn’t
                          just for bedtime.
                        </p>
                      </div>
                    
                    }
                    </Spring>
                    )}
                  </VisibilitySensor>


                </Col>
              </Row>

          </div>

</ParallaxLayer> */}








{/* ---------- Parallax ! ----------  */}


</Parallax>

{/* ---------- Layout ! ----------  */}

            </Layout>

        );
    }
}

export default IndexPage;
